
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_9387nSaK1LNPMeta } from "/vercel/path0/pages/nieuws/[slug].vue?macro=true";
import { default as indexdrRNPOpoZlMeta } from "/vercel/path0/pages/nieuws/index.vue?macro=true";
import { default as _91slug_93mLi992iyd2Meta } from "/vercel/path0/pages/projecten/[slug].vue?macro=true";
import { default as indexeorJYSORBeMeta } from "/vercel/path0/pages/projecten/index.vue?macro=true";
import { default as _91_46_46_46slug_93OdtlCd6qLxMeta } from "/vercel/path0/pages/quickscan/[...slug].vue?macro=true";
import { default as indexgAcSp3LXtVMeta } from "/vercel/path0/pages/quickscan/index.vue?macro=true";
import { default as _91slug_938sQ79P77cGMeta } from "/vercel/path0/pages/regelingen/[slug].vue?macro=true";
import { default as indexFUIoZODq4eMeta } from "/vercel/path0/pages/regelingen/index.vue?macro=true";
import { default as _91slug_93FtM5z0EwkaMeta } from "/vercel/path0/pages/steun-ons/[slug].vue?macro=true";
import { default as indexhbhAV46nV3Meta } from "/vercel/path0/pages/steun-ons/index.vue?macro=true";
import { default as teamThGqkFwfKIMeta } from "/vercel/path0/pages/team.vue?macro=true";
import { default as zoektptcXuLEE1Meta } from "/vercel/path0/pages/zoek.vue?macro=true";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "nieuws-slug___nl",
    path: "/nieuws/:slug()",
    component: () => import("/vercel/path0/pages/nieuws/[slug].vue")
  },
  {
    name: "nieuws___nl",
    path: "/nieuws",
    component: () => import("/vercel/path0/pages/nieuws/index.vue")
  },
  {
    name: "projecten-slug___nl",
    path: "/projecten/:slug()",
    component: () => import("/vercel/path0/pages/projecten/[slug].vue")
  },
  {
    name: "projecten___nl",
    path: "/projecten",
    meta: indexeorJYSORBeMeta || {},
    component: () => import("/vercel/path0/pages/projecten/index.vue")
  },
  {
    name: "quickscan-slug___nl",
    path: "/quickscan/:slug(.*)*",
    component: () => import("/vercel/path0/pages/quickscan/[...slug].vue")
  },
  {
    name: "quickscan___nl",
    path: "/quickscan",
    component: () => import("/vercel/path0/pages/quickscan/index.vue")
  },
  {
    name: "regelingen-slug___nl",
    path: "/regelingen/:slug()",
    component: () => import("/vercel/path0/pages/regelingen/[slug].vue")
  },
  {
    name: "regelingen___nl",
    path: "/regelingen",
    component: () => import("/vercel/path0/pages/regelingen/index.vue")
  },
  {
    name: "steun-ons-slug___nl",
    path: "/steun-ons/:slug()",
    component: () => import("/vercel/path0/pages/steun-ons/[slug].vue")
  },
  {
    name: "steun-ons___nl",
    path: "/steun-ons",
    component: () => import("/vercel/path0/pages/steun-ons/index.vue")
  },
  {
    name: "team___nl",
    path: "/team",
    component: () => import("/vercel/path0/pages/team.vue")
  },
  {
    name: "zoek___nl",
    path: "/zoek",
    component: () => import("/vercel/path0/pages/zoek.vue")
  }
]