import payload_plugin_Z0cFW4pZ8F from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_O7X019Byyw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qkUQIDtOe3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8XdgmHZOSt from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_kgNoVO8NgW from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.34.8_vite@6.2.0_@types+node@20.17.19_jiti@2.4_e5oado57zdvlh3renwij6dbfqu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_fyrnv6awEI from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KlO2Sr6oV6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oqb85bkZWA from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_X8RSshQ6oj from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_xsMnQV1RVK from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_4bUGhTHvwx from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_zIcNpmyKjH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0__v23gstlbd5oy2frbgbhwdlpy7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_MdLpaPV09V from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_eslint@8.57.1_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_D8Ywm2KpJl from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_eslint@8.57.1_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_QAKuh45XvW from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_eslint@8.57.1_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_1VKWzK3aTi from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.10.0_magicast@0.3.5_nuxt@3.15.4_@parcel+watch_rxadcukdo7mr2uzkhkuqixftgi/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_TXlY2b5e2s from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.2.0_magicast@0.3.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import ssg_detect_N3PADDJL4e from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_eslint@8.57.1_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_Z0cFW4pZ8F,
  revive_payload_client_O7X019Byyw,
  unhead_qkUQIDtOe3,
  router_8XdgmHZOSt,
  _0_siteConfig_kgNoVO8NgW,
  payload_client_fyrnv6awEI,
  navigation_repaint_client_KlO2Sr6oV6,
  check_outdated_build_client_oqb85bkZWA,
  chunk_reload_client_X8RSshQ6oj,
  cross_origin_prefetch_client_xsMnQV1RVK,
  plugin_vue3_4bUGhTHvwx,
  components_plugin_zlvi6dcIsi,
  prefetch_client_zIcNpmyKjH,
  switch_locale_path_ssr_MdLpaPV09V,
  route_locale_detect_D8Ywm2KpJl,
  i18n_QAKuh45XvW,
  plugin_1VKWzK3aTi,
  plugin_TXlY2b5e2s,
  ssg_detect_N3PADDJL4e
]