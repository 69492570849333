export const usePageCTASTore = defineStore('cta-store', {
    state: () => {
        return {
            callToActions: [],
            isLoading: false
        };
    },

    actions: {
        load(callToActions = []) {
            this.callToActions = callToActions;
        },

        toggleLoadingState() {
            this.isLoading = !this.isLoading;
        },

        enableLoadingState() {
            this.isLoading = true;
        },

        disableLoadingState() {
            this.isLoading = false;
        }
    }
});
